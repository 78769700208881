#tutorial-backdrop .logo--full,
#tutorial-backdrop .nav__label {
  opacity: 1;
  visibility: visible;
}
#tutorial-backdrop .logo--icon {
  opacity: 0;
}

#tutorial-backdrop #tutorial-profile-button,
#tutorial-backdrop #tutorial-new-address,
#tutorial-backdrop #tutorial-bulk-upload,
#tutorial-backdrop #tutorial-channel-territory,
#tutorial-backdrop .tutorial-highlight {
  background: white;
  border-radius: 4px;
  outline: 12px solid white !important;
}
