:root {
  --sidebar-open-width: 250px;
  --sidebar-close-width: 4em;
  --header-height: 108px;
  --sidebar-width: var(--sidebar-close-width);

  --last-child-min-height: 300px;
  --mobile-pb: 768px;
}

@media screen and (max-width: 768px) {
  :root {
    --sidebar-close-width: 0px;
    --header-height: 92px;
  }
  body {
    .sidebar {
      height: calc(100% - 8px);
    }
    .sidebar-area,
    .sidebar-area--open {
      width: 0px !important;
    }
    .sidebar-area:not(.sidebar-area--open) .sidebar {
      width: 0px !important;
    }
    .sidebar-area--open .sidebar {
      width: var(--sidebar-open-width);
    }
    .body-height-after-header {
      @apply h-auto;
    }
    .body-height-after-header > *:last-child {
      @apply h-auto max-h-screen overflow-auto;
    }
  }
}

.container-lg {
  max-width: 2000px;
  @apply px-2 md:px-4 lg:w-[90%] mx-auto;
}

.main-grid {
  grid-template-columns: auto 1fr;
  grid-template-areas: "sidebar main";
  @apply transition grid relative gap-1 lg:gap-3 h-screen overflow-hidden;
}

.sidebar__list {
  direction: rtl;
}

.sidebar__list > div {
  direction: ltr;
}

.sidebar-area {
  --hover-reveal-delay: 300ms;
  --label-reveal-duration: 200ms;
  transition: width 0.2s ease-in-out;
  width: var(--sidebar-close-width);
  grid-area: sidebar;
  position: relative;
  @apply h-screen flex overflow-x-visible ml-1 lg:ml-3;
}

.logo__container {
  position: relative;
  @apply h-14 w-full overflow-hidden mt-2 mb-6;
}
.logo {
  transition: opacity 0.2s linear;
  @apply absolute object-contain inset-0 px-4 h-full w-full object-center;
}

.sidebar-area--open .logo--full,
.sidebar:hover .logo--full,
.logo--icon {
  opacity: 1;
}
.logo--full,
.sidebar-area--open .logo--icon,
.sidebar:hover .logo--icon {
  opacity: 0;
}
.sidebar:hover {
  .logo--icon,
  .logo--full {
    transition-delay: var(--hover-reveal-delay);
  }
}
.nav__icon {
  @apply p-1 text-primary-600;
}
.nav__label {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--label-reveal-duration) linear,
    visibility 0s var(--label-reveal-duration) linear;
  @apply whitespace-nowrap ml-4 max-w-full overflow-ellipsis overflow-hidden;
}

.nav__submenu {
  will-change: height;
  transition: height 0.2s linear;
}

.sidebar-area--open {
  width: var(--sidebar-open-width);
}
.sidebar-area--open .nav__label {
  opacity: 1;
  visibility: visible;
  transition:
    opacity var(--label-reveal-duration) linear,
    visibility 0s linear;
}
.sidebar:hover .nav__label {
  opacity: 1;
  visibility: visible;
  transition:
    opacity var(--label-reveal-duration) var(--hover-reveal-delay) linear,
    visibility 0s var(--hover-reveal-delay) linear;
}

.sidebar:hover {
  transition-delay: var(--hover-reveal-delay);
  width: var(--sidebar-open-width);
}

.sidebar {
  width: var(--sidebar-close-width);
  transition: width 0.2s ease-in-out;
  will-change: width;
  z-index: 2999;
  position: relative;
  @apply bg-gradient-to-br from-neutral-900 to-neutral-700 rounded-xl shadow-lg
      w-full my-1 lg:my-3
      text-neutral-300;
}

.sidebar {
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-neutral-500 rounded;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-neutral-500;
  }

  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.25) transparent;
}

.header__container {
  @apply sticky pt-2 lg:pt-6 -top-1 lg:-top-3 mb-2 lg:mb-6;
}
.header {
  @apply rounded-xl bg-transparent transition duration-300;
}

.header__container--pinned .header {
  @apply bg-white/75 shadow-md backdrop-blur-md;
}

.sidebar-area--open + .body {
  --sidebar-width: var(--sidebar-open-width);
}

.body {
  grid-area: main;
  overflow-y: auto;
  @apply pr-1 lg:pr-3 relative;
}

.body-height-after-header > * {
  @apply flex-none;
}
.body-height-after-header > *:last-child {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  /* min-height: var(--last-child-min-height); */
}
.body-height-after-header {
  height: calc(100vh - var(--header-height) - var(--space-after-header, 0px));
  @apply flex flex-col;
}
